<template>
  <div class="NotificationsPanel">
    <div class="NotificationsPanel__title">
      <h2>{{ $t('notifications') }}</h2>

      <ul class="CustomPagination" v-if="pageCount > 1">
        <li class="CustomPagination__Item" :class="{ disabled: isFirstPage }" @click="prevPage">
          &laquo;
        </li>
        <li v-for="p in pages" :key="p" class="CustomPagination__Item" :class="{ active: p === localPage }"
          @click="goToPage(p)">
          {{ p }}
        </li>
        <li class="CustomPagination__Item" :class="{ disabled: isLastPage }" @click="nextPage">
          &raquo;
        </li>
      </ul>
    </div>

    <preloader class="NotificationsPanel__Loader" v-if="loadingPaginatedAlerts">
      <h2>{{ $t('notifications_loading') }}</h2>
    </preloader>

    <div class="Notifications" v-else>
      <ul class="Notifications__List" v-if="paginatedAlerts && paginatedAlerts.length">
        <li class="Notifications__List--Item" v-for="n in paginatedAlerts" :key="n.id"
          @mouseover="clearNotifications(n.id)">
          <notification-item :text="n.text.translated" :date="n.creationDate" :type="n.type" :seen="n.seen"
            @open-notification="
              openNewScreen($event, n.activityId, n.communityId, n.commentId, n.commentPage)
              " />
        </li>
      </ul>

      <ul class="Notifications__List" v-else>
        <li class="Notifications__List--Item">
          <h3 style="text-align:center">
            {{ $t('notification_up_to_date') }}
          </h3>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import NotificationItem from '@/components/NotificationItem.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  EL_MEDAL,
  WL_MEDAL,
  EL_ANSWER,
  WL_ANSWER,
  EL_ACT_REMINDER,
  WL_ACT_REMINDER,
  EL_ACT_LAUNCHED,
  WL_ACT_LAUNCHED,
  EL_COM_LAUNCHED,
  WL_COM_LAUNCHED,
  EL_MENTION,
  WL_MENTION,
  EL_MOD_NEW_ANSWER_TO_COMMENT,
  WL_NEW_ANSWER_TO_COMMENT,
  WL_MOD_NEW_ANSWER_TO_COMMENT,
  EL_MOD_MENTIONED_IN_COMMENT,
  WL_MOD_MENTIONED_IN_COMMENT,
  EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
  WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED
} from '~/constants/constants.type';
import {
  NOTIFICATIONS_FETCH_PAGINATED,
  NOTIFICATIONS_CLEAR_ID
} from '@/store/notifications/notifications.module';

export default {
  name: 'NotificationsPage',
  components: {
    Preloader,
    NotificationItem
  },
  data() {
    return {
      // Se incluyen todas las variantes para usarlas en las comparaciones
      EL_MEDAL,
      WL_MEDAL,
      EL_ANSWER,
      WL_ANSWER,
      EL_ACT_REMINDER,
      WL_ACT_REMINDER,
      EL_ACT_LAUNCHED,
      WL_ACT_LAUNCHED,
      EL_COM_LAUNCHED,
      WL_COM_LAUNCHED,
      EL_MENTION,
      WL_MENTION,
      EL_MOD_NEW_ANSWER_TO_COMMENT,
      WL_NEW_ANSWER_TO_COMMENT,
      WL_MOD_NEW_ANSWER_TO_COMMENT,
      EL_MOD_MENTIONED_IN_COMMENT,
      WL_MOD_MENTIONED_IN_COMMENT,
      EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
      WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
      localPage: 1,
      localPageSize: 20
    };
  },
  computed: {
    ...mapGetters([
      'paginatedAlerts',
      'loadingPaginatedAlerts',
      'paginatedTotal',
      'isClient'
    ]),
    pageCount() {
      return Math.ceil(this.paginatedTotal / this.localPageSize) || 1;
    },
    pages() {
      const arr = [];
      if (this.localPage > 1) arr.push(this.localPage - 1);
      arr.push(this.localPage);
      if (this.localPage < this.pageCount) arr.push(this.localPage + 1);
      return arr;
    },
    isFirstPage() {
      return this.localPage === 1;
    },
    isLastPage() {
      return this.localPage === this.pageCount;
    }
  },
  methods: {
    ...mapActions([NOTIFICATIONS_FETCH_PAGINATED]),
    async loadPage() {
      const zeroBasedPage = this.localPage - 1;
      await this[NOTIFICATIONS_FETCH_PAGINATED]({
        page: zeroBasedPage,
        pageSize: this.localPageSize,
        isClient: this.isClient,
        hideSpinner: false
      });
    },
    goToPage(p) {
      this.localPage = p;
      this.loadPage();
    },
    nextPage() {
      if (!this.isLastPage) {
        this.localPage++;
        this.loadPage();
      }
    },
    prevPage() {
      if (!this.isFirstPage) {
        this.localPage--;
        this.loadPage();
      }
    },
    navigateTo(name, arg) {
      this.$emit('close-panel');
      const params = { origin: this.$route.params.origin };
      if (arg) params.id = arg;
      this.$router.push({ name, params });
    },
    async scrollToComment(commentId) {
      await this.$nextTick();
      const commentElement = document.querySelector(`.comment_${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        commentElement.style.backgroundColor = 'rgba(255, 215, 0, 0.3)';
        commentElement.style.padding = '5px';
        commentElement.style.borderRadius = '5px';
        commentElement.style.transition = 'all 0.5s ease';
        setTimeout(() => {
          commentElement.style.backgroundColor = 'transparent';
          commentElement.style.padding = '0';
          commentElement.style.borderRadius = '0';
        }, 5000);
      }
    },
    openNewScreen(event, act, cmty, cmnt, cmntP) {
      const { type } = event;
      console.log("openNewScreen type:", type);
      const currentRoute = this.$route;
      const isCurrentRoute = (name, params) => {
        return (
          currentRoute.name === name &&
          (!params || Object.keys(params).every(key => currentRoute.params[key] === params[key]))
        );
      };

       if (type === this.EL_COM_LAUNCHED || type === this.WL_COM_LAUNCHED) {
        if (!isCurrentRoute('welcome')) {
          this.navigateTo('welcome');
        }
      }
      // Notificación de actividad lanzada o recordatorio o actividad lanzada moderada
      else if (
        type === this.EL_ACT_LAUNCHED || type === this.WL_ACT_LAUNCHED ||
        type === this.EL_ACT_REMINDER || type === this.WL_ACT_REMINDER ||
        type === this.EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED || type === this.WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED
      ) {
        if (!isCurrentRoute('activity.info', { id: act })) {
          this.navigateTo('activity.info', act);
        }
      }
      // Notificación de mención, respuesta o moderación (incluyendo las de moderador de respuesta o mención)
      else if (
        type === this.EL_MENTION || type === this.WL_MENTION ||
        type === this.EL_ANSWER || type === this.WL_ANSWER ||
        type === this.EL_MOD_NEW_ANSWER_TO_COMMENT || type === this.WL_NEW_ANSWER_TO_COMMENT ||
        type === this.WL_MOD_NEW_ANSWER_TO_COMMENT || type === this.EL_MOD_MENTIONED_IN_COMMENT ||
        type === this.WL_MOD_MENTIONED_IN_COMMENT ||
        type === this.EL_MEDAL || type === this.WL_MEDAL
      ) {
        if (isCurrentRoute('activity.info', { id: act })) {
          this.scrollToComment(cmnt);
        } else {
          this.$router.push({
            name: 'activity.info',
            params: { notificationCommentId: cmnt, notificationCommentPage: cmntP, id: act }
          }).then(() => {
            this.scrollToComment(cmnt);
          });
        }
      } else {
        console.warn('Tipo de notificación no reconocido:', type);
      }
    },
    clearNotifications(id) {
      this.paginatedAlerts.find(n => {
        if (n.id === id && !n.seen) {
          this.$store.dispatch(NOTIFICATIONS_CLEAR_ID, {
            ids: [id],
            isClient: this.isClient,
            hideSpinner: false
          });
          n.seen = true;
          return;
        }
      });
    }
  },
  created() {
    this.loadPage();
  }
};
</script>

<style lang="scss">
.container-fluid {
  height: 100% !important;
  min-height: unset !important;
}
</style>

<style scoped lang="scss">
.NotificationsPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  background: white;

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    box-shadow: 0px 2px 2px #f1f1f1;
    align-items: center;

    h2 {
      padding: 10px;
      font-size: 14px;
    }
  }

  &__Loader {
    flex-grow: 1;
    width: 100%;
    padding: 3rem 1rem;
    text-align: center;
    color: var(--primary-color, #333);
    animation: blink 2s linear infinite;

    @keyframes blink {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .Notifications {
    width: 100%;
    padding: 3rem;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    &__List {
      list-style-type: none;

      &--Item {
        padding: 1rem;
        border-bottom: 1px solid lightgray;
      }
    }
  }
}

.CustomPagination {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;

  &__Item {
    cursor: pointer;
    margin: 0 5px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    color: var(--primary-color);
    border-radius: 4px;
    transition: background-color 0.2s;

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
      color: #999;
    }

    &.active {
      background-color: var(--primary-color);
      color: #fff;
      border-color: var(--primary-color);
      font-weight: bold;
    }

    &:hover:not(.disabled):not(.active) {
      background-color: #f2f2f2;
    }
  }
}
</style>
